<template>
  <div
    v-if="closestEdge === 'top'"
    class="w-full border-t border-primary-500"
  />
  <div
    v-if="field.type"
    ref="element"
    class="rounded-md border border-gray-200 p-3 group relative"
  >
    <div
      v-if="field.id"
      ref="dragElement"
      class="absolute group-hover:flex hidden -left-7 top-1/2 -translate-y-1/2 w-8 h-full cursor-grab items-center justify-center"
    >
      <IconDrag v-if="showWrapper" />
    </div>
    <div v-if="showWrapper" class="flex justify-between items-center hover:cursor-pointer" @click="isCollapse = !isCollapse">
      <div class="flex items-center gap-3">
        <FieldIcon :type="field.type" />
        <div v-if="field.id">
          <p class="text-sm">{{ field.name }}</p>
          <p v-if="field.description" class="text-xs text-gray-500 mt-1">
            {{ field.description }}
          </p>
        </div>
      </div>
      <UButton
        :icon="collapseIcon"
        color="gray"
        size="xs"
        variant="ghost"
      />
    </div>
    <div v-if="!isCollapse" class="mt-4">
      <UFormGroup label="Field type" name="type" class="mb-4">
        <UPopover
          v-model:open="open"
          :ui="{ width: 'w-full' }"
          :popper="{
            placement: 'bottom-start',
            strategy: 'absolute',
          }"
        >
          <template #panel>
            <div
              class="max-h-96 scroll-stable overflow-y-auto minimal-scrollbar"
            >
              <div class="px-3 py-2 ">
                <div class="space-y-2 mt-2">
                  <div
                    v-for="item in FieldLists"
                    :key="item.type"
                    class="flex items-center gap-2 cursor-pointer rounded hover:bg-gray-50 p-1"
                    @click="onSelectField(item.type)"
                  >
                    <FieldIcon :type="item.type" />
                    <span class="text-sm font-gray-900">{{ item.label }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <UButton
            :disabled="!!field.id"
            class="w-full flex justify-between text-gray-700"
            :color="!!field.id ? 'gray' : 'white'"
            trailing-icon="i-heroicons-chevron-down-20-solid"
          >
            <div class="flex items-center gap-2">
              <FieldIcon :type="field.type" />
              {{ fieldName }}
            </div>
          </UButton>
        </UPopover>
      </UFormGroup>
      <Suspense>
        <component
          :is="FieldFormComponents.get(field.type)"
          ref="formRef"
          :initial-value="field"
          @submit="(...args: unknown[]) => $emit('submit', ...args)"
        >
          <template #footer>
            <div class="flex justify-end gap-2">
              <UButton
                v-if="allowRemove && field.id"
                variant="soft"
                color="red"
                :disabled="loading"
                @click="onUnattachField"
              >
                {{ deleteButtonLabel }}
              </UButton>
              <UButton
                v-else
                variant="soft"
                color="gray"
                :disabled="loading"
                data-test="field-submit"
                @click="$emit('remove')"
              >
                Cancel
              </UButton>
              <UButton size="sm" type="submit" :loading="loading" data-test="submit-btn">
                Save
              </UButton>
            </div>
          </template>
        </component>
      </Suspense>
    </div>
  </div>
  <div
    v-if="closestEdge === 'bottom'"
    class="w-full border-b border-primary-500"
  />
  <Teleport v-if="state.type === 'preview'" :to="state.container">
    <div
      class="box-border flex items-center gap-3 relative bg-white border border-gray-200 rounded-md shadow-2xl h-8 max-w-96 px-2 truncate text-xs leading-8 -rotate-2"
    >
      <IconDrag />
      {{ field.name }}
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { FieldFormComponents, FieldLists, type FieldType } from '#field/constant'
import type { Field } from '#field/types'
import { LazyDeleteModal } from '#components'

const props = defineProps({
  field: {
    type: Object as PropType<Partial<Field>>,
    required: true,
  },
  taskTypeId: {
    type: String,
  },
  pivot: {
    type: Object as PropType<{ id: string; position: number }>,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  collapse: {
    type: Boolean,
    default: false,
  },
  showWrapper: {
    type: Boolean,
    default: true,
  },
  allowRemove: {
    type: Boolean,
    default: true,
  },
  scope: {
    type: String,
    default: 'task',
  }
})

const emit = defineEmits(['submit', 'remove', 'select'])

const modal = useModal()
const { preventClose } = useBoardSettingsNavigator()

const isCollapse = ref(props.collapse)
const element = ref()
const dragElement = ref()
const open = ref(false)

const { state, closestEdge } = useFieldAsDropTarget({
  item: {
    ...props.pivot!,
    taskTypeId: props.taskTypeId!,
  },
  target: element,
  dragHandleTarget: dragElement,
})

const deleteButtonLabel = computed(() => props.scope === 'task' ? 'Remove field from this task' : 'Delete')

const collapseIcon = computed(() =>
  isCollapse.value
    ? 'i-heroicons-chevron-right-20-solid'
    : 'i-heroicons-chevron-down-20-solid'
)

const fieldName = computed(() => FieldLists.find((field) => field.type === props.field.type)?.label || '')

const onSelectField = (type: FieldType) => {
  emit('select', type)
  open.value = false
}

const onUnattachField = () => {
  preventClose.value = true
  modal.open(LazyDeleteModal, {
    heading: `Delete "${props.field.name}" field from this ${props.scope}?`,
    confirmText: 'Delete field',
    onConfirm: async () => {
      emit('remove')
      modal.close()
      preventClose.value = false
    },
    onCancel: () => {
      modal.close()
      preventClose.value = false
    },
  })
}
</script>
